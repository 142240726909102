import React from "react";
import './styles.scss';

export default function Card(props) {

  let classes = ' ';
  if(props.size === 'LARGE') classes += 'card--large ';
  if(props.size === 'REGULAR') classes += 'card--regular ';

  return (
    <a 
      href={props.link} 
      rel="noopener noreferrer" 
      target="_blank" 
      className={`card ${classes}`}>
        <div className="card__inner">
          <div className="card__imageContainer">
            <img src={props.image} alt={props.title} className="card__image"/>
          </div>
          {props.size === 'LARGE' && <div className="card__gradient"/>}
          <h3 className="card__title">{props.title}</h3>
        </div>
    </a>
  )
}

import React, {useState} from "react";
import sectionData from './sectionData';
import YouTube from 'react-youtube-embed';
import './styles.scss';

function SectionContent(props) {
    return (
        <div className="youtubeSection__sections">
            {sectionData.map((section, i) => {
                var indexPlus = i + 1;
                
                if (indexPlus === props.sectionToShow) {
                    return (
                        <div className="youtubeSection__section" key={i}>
                            <h3 className="youtubeSection__section__title">{section.title}</h3>
                            <p className="youtubeSection__section__copy">{section.copy}</p>
                            <YouTube id={section.youtubeId} />
                        </div>
                    )
                } else {
                    return <div key={i}/>
                }
            })}
        </div>
    )
}

function YoutubeSection(props) {
    const [sectionToShow, setSectionToShow] = useState(1);
  
    function SectionList() {
        return (
            [
                <div 
                    key={1}
                    className="tablet-up youtubeSection__navListContainer">
                    <ul className="youtubeSection__navList">
                        {sectionData.map((section, i) => {
                            return (
                                <li 
                                    key={i}
                                    className={sectionToShow === i+1 ? 'active': ''}
                                    onClick={() => {setSectionToShow(i+1)}}>
                                    {section.title}
                                </li>
                            )
                        })}
                    </ul>
                </div>,
                <div
                    key={2}
                    className="tablet-down youtubeSection__selectMasterContainer">
                    <div className="youtubeSection__selectWrapper">
                        <select   
                            onChange={(e) => {
                                const value = e.target.value;
                                setSectionToShow(parseInt(value));
                            }}
                            value={sectionToShow}
                            className="youtubeSection__select">
                            {sectionData.map((section, i) => {
                                return (
                                    <option 
                                        key={i}
                                        value={i+1}>
                                        {section.title}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>,
            ]
        )
    }
    
    return (
        <div className="youtubeSection" id="youtubeSection">
            <div className="youtubeSection__inner">
                <h2 className="youtubeSection__title">Credit Coach</h2>
                <p className="youtubeSection__desc">Check out these tips from our Credit Coach to help you better understand, leverage, and improve your credit score. </p>
                <SectionList/>
                <SectionContent sectionToShow={sectionToShow} />
            </div>
        </div>
    )
}

export default YoutubeSection;

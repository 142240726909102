import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "main/App";

//Components
import Home from "pages/home";

export const communityUrl = 'https://help.creditsesame.com/hc/en-us/community/topics';

function RoutesComponent(props) {
  /** Routes component **/
  return (
    <BrowserRouter>
      <Main {...props}>
        <Switch location={props.location}>
          <Route path={"/"} exact={true} render={routeProps => <Home {...routeProps} />} />
          <Route path='/community' component={() => { 
            window.location.href = communityUrl;
            return null;
          }}/>
        </Switch>
      </Main>
    </BrowserRouter>
  );
}

export default RoutesComponent;

import React, { useEffect } from "react";
import postsData from "pages/home/components/Data/postsData";
import creditTools from "pages/home/components/CreditTools/creditTools";
import { scrollTo } from "utility";
import { init, toggleDownload, closeDownloadAction } from "./helper"
import "./styles.scss";

const creditSesame = require("images/logo.svg");
const communityLink = "https://help.creditsesame.com/hc/en-us/community/topics";
function List() {

  function handleClick(e) {
    e.target.parentElement.classList.toggle('open');
    //console.log(e)
  }

  return (
    <ul className="header__linkList">
      <li 
        onClick={(e)=>{handleClick(e)}}
        className="header__link header__link--hasSubnav">
        <span>Resources</span>
        <ul className="header__subnav">
          {postsData.map((category,i) => (
            <li 
              key={i} 
              onClick={(e)=>{
                scrollTo(`[data-section="${category.categoryTitle}"]`);
                closeDownloadAction();
              }}>
                <span>{category.categoryTitle}</span>
            </li>
          ))}
        </ul>
      </li>
      <li 
        className="header__link"
        onClick={()=>{
          scrollTo(`#youtubeSection`);
          closeDownloadAction();
        }}>
        <span>Credit Coach</span>
      </li>
      <li 
        onClick={(e)=>{handleClick(e)}}
        className="header__link header__link--hasSubnav">
        <span>Budget calculators</span>
        <ul className="header__subnav">
              {creditTools.map((category,i) => (
                          <li  key={i}
          onClick={(e)=>{handleClick(e)}}>
                    <span><a href={category.categoryLink} target="_self">{category.categoryTitle}</a></span>
                    </li>
              ))}
        </ul>
      </li>
      <li 
        className="header__link"
        onClick={()=>{
          window.location.href = communityLink;
        }}>
        <span>Community</span>
      </li>
    </ul>
  )
}

function Navigation(props) {
  useEffect(()=>{
    init()
  },[])

  return (
    <header id="header" className="header">
      <div className="header__inner">
        <div className="header__left">
          <img className="header__logo" alt="SesameThrive" src={creditSesame} />
        </div>
        <div className="header__right">
          <div className="tablet-down">
            <img 
              src={require('images/asset--menu-icon.png')}
              alt="Menu"
              onClick={toggleDownload}
              className="header__mobileIcon toggle-popup"
            />
          </div>
          <div className="tablet-up">
            <List/>
          </div>
        </div>
      </div>
      <div className="header__downloadButtonsContainer mobile">
        <div className="header__downloadButtonsContainer__inner">
          <div className="header__downloadButtonsContainer__closeButton" id="closeButton">
            x
          </div>
          <div className="header__downloadButtonsContainer__actionContainer">
            <List/>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navigation;

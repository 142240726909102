import React from "react";
import Card from "pages/home/components/Card";
import postsData from "pages/home/components/Data/postsData";
import './styles.scss'

function ArticleSection(props) {
    //props = 1st level object supplied from data array 
    return (
        <section className="articleSection" data-section={props.categoryTitle}>
            <h2 className="articleSection__title">{props.categoryTitle}</h2>
            <div className="articleSection__posts">
                {props.articles.map((article, i) => {
                    return i === 0 ? <Card key={i} size='LARGE' {...article} /> : <Card key={i} size='REGULAR' {...article} />;
                })}
            </div>
            <div className="articleSection__allPostsLinkContainer">
                <a 
                    //href={props.categoryLink} 
                    href="https://www.creditsesame.com/blog/updates/manage-finances-covid-19/"
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="articleSection__allPostsLink">
                    {props.categoryViewAllText}
                    <img src={require('images/icon--caret-right.png')} alt="Caret" className="articleSection__allPostsLink__caret" />
                </a>
            </div>
        </section>
    )
}

export default function Articles() {
  return (
    <div id="articles" className="articlesSections">
        {postsData.map((data, i) => <ArticleSection key={i} {...data} />)}
    </div>
  );
}

export default [
    {
        categoryTitle: '50/30/20 Budget Calculator',
        categoryLink: 'https://www.creditsesame.com/blog/updates/50-30-20-budget-calculator/'
    },
    {
        categoryTitle: 'Powercash Budget Calculator',
        categoryLink: 'https://www.creditsesame.com/blog/updates/powercash-budget-calculator/'
    },
    {
        categoryTitle: 'Credit Card Interest Calculator',
        categoryLink: 'https://www.creditsesame.com/blog/updates/credit-card-interest-calculator/'
    },
    {
        categoryTitle: 'Debt-to-Income Ratio Calculator',
        categoryLink: 'https://www.creditsesame.com/blog/updates/debt-to-income-ratio-calculator/'
    },
    {
        categoryTitle: 'Simple Budget Calculator',
        categoryLink: 'https://www.creditsesame.com/blog/updates/simple-budget-calculator/'
    }
]
import React from "react";
import './styles.scss';
import {communityUrl} from 'routes';

function CommunitySection(props) {
    return (
        <div className="communitySection" id="communitySection">
            <div className="communitySection__inner">
                <div className="communitySection__ghandiQuote">
                    <p className="communitySection__ghandiQuote__text">"Be the change that you wish to see in the world."</p>
                    <p className="communitySection__ghandiQuote__author">- Gandhi</p>
                </div>
                <h1 className="communitySection__title">Thrive Community</h1>
                <p className="communitySection__desc">Become a vital part of the SesameThrive community and share your experiences and insights of managing your own credit and cash. Make a difference by empowering others to achieve their financial goals.</p>
                <a rel="noopener noreferrer" href={communityUrl} className="communitySection__button" target="_blank">Join the Discussion</a>
            </div>
        </div>
    )
}

export default CommunitySection;

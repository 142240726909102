import anime from "animejs/lib/anime.es.js";

export const scrollTo = element => {
  const elementSelector = document.querySelector(element);
  const elementOffset = elementSelector.getBoundingClientRect().top;
  const scrollPosition = window.scrollY;
  const documentTop = document.documentElement.clientTop;

  const scrollOffset = elementOffset + scrollPosition - documentTop - 100;

  anime({
    targets: [document.documentElement, document.body],
    scrollTop: scrollOffset,
    duration: 800,
    easing: "easeInOutQuad"
  });
};

import React from "react";
import './styles.scss';

//Navigation
import Navigation from "pages/home/components/Navigation";
import Articles from "./components/Articles";
import YoutubeSection from "./components/YoutubeSection";
import CommunitySection from "./components/Community";
import Footer from "./components/Footer";

function App() {
  return (
    <div id="homePage">
      <Navigation />
      <div className="intoTitleContainer">
        <img src={require('images/asset--seed.png')} alt="SesameThrive"  className="intoTitleContainer__seed" />
        <h1 className="intoTitleContainer__title">Your Financial Wellness Begins here</h1>
        <p className="intoTitleContainer__text">Put yourself in control of your financial wellness. With SesameThrive, you have a trusted and knowledgeable guide to empower you to take charge of your cash and credit and help you achieve your financial goals. Don’t just weather the storms that life brings—thrive on the other side.</p>
      </div>
      <Articles />
      <YoutubeSection/>
      <CommunitySection/>
      <Footer/>
    </div>
  );
}

export default App;

import React from "react";
import './styles.scss';

export default function() {
    return (
        <footer class="footer__wrapper">
            <div class="footer__inner">
                <div>This content is for informational purposes only and should not be relied on as financial advice.</div>
                <ul class="footer__links">
                    <li>
                        <a href="https://www.creditsesame.com/about/privacy-policy/">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="https://www.creditsesame.com/about/terms-of-use/">Terms of Use</a>
                    </li>
                    <li>
                        <a href="https://www.creditsesame.com/about/community-guidelines/">Community Guidelines</a>
                    </li>
                </ul>
                <div class="footer__copy">Copyright © 2020 Credit Sesame, Inc.</div>
                <div>444 Castro St., Ste 500, Mountain View, CA 94041</div>
                <div>100 Montgomery St., Ste 2500, San Francisco, CA 94104</div>
            </div>          
        </footer>
    );
}
export default [
    {
        categoryTitle: 'Dealing with Loss of Income',
        //categoryLink: 'https://google.ca/',
        categoryViewAllText: 'View all Articles',
        articles: [
            {
                title: '5 Quick Steps to Start Building Your Emergency Fund Now',
                link: 'https://www.creditsesame.com/blog/savings/5-steps-building-emergency-fund/',
                image: require('images/posts/asset--corona-emergencyfund-hero.jpg')
            },
            {
                title: 'Ways to Help Cut Expenses and Save Money',
                link: 'https://www.creditsesame.com/blog/updates/cut-expenses-save-money/',
                image: require('images/posts/asset--corona-cutexpenses-hero.jpeg')
            },
            {
                title: 'What You Can Do if You Lost Your Job as a Result of the Coronavirus',
                link: 'https://www.creditsesame.com/blog/updates/what-you-can-do-if-you-lost-your-job-as-a-result-of-the-coronavirus/',
                image: require('images/posts/asset--corona-job-loss-hero.jpeg')
            },
            {
                title: 'Do You Qualify for a Stimulus Payment? (Calculator)',
                link: 'https://www.creditsesame.com/blog/updates/qualify-for-stimulus-payment/',
                image: require('images/posts/asset--corona-utilities-bills-hero.jpeg')
            }, 
            {
                title: 'Check the Status of your Stimulus Payment through the IRS Portal',
                link: 'https://www.creditsesame.com/blog/updates/check-status-stimulus-payment/',
                image: require('images/posts/asset--corona-stimulus-payment-hero.jpeg')
            },
            {
                title: 'Maximize your Coronavirus Stimulus and Tax Refund Checks',
                link: 'https://www.creditsesame.com/blog/updates/maximize-coronavirus-stimulus-tax-refund-checks/',
                image: require('images/posts/asset--corona-stimulus-hero.jpeg')
            },
            {
                title: 'Applying for an Emergency Credit Card',
                link: 'https://www.creditsesame.com/blog/updates/applying-emergency-credit-card/',
                image: require('images/posts/asset--emerg-credit-card.jpeg')
            },
            {
                title: 'Are you a small business owner impacted by the pandemic?',
                link: 'https://www.creditsesame.com/blog/updates/small-business-covid-19/',
                image: require('images/posts/asset--corona-small-business-owner-hero.jpeg')
            },
            {
                title: 'What the CARES Act says about 401(k)s',
                link: 'https://www.creditsesame.com/blog/updates/cares-act-401k/',
                image: require('images/posts/asset--corona-cares-act-hero.jpeg')
            },
        ]
    },
    {
        categoryTitle: 'Managing Your Bills',
        //categoryLink: 'https://google.ca/',
        categoryViewAllText: 'View all Articles',
        articles: [
            // {
            //     title: 'Top 5 Recommended Budgeting Tools',
            //     link: 'https://www.creditsesame.com/blog/updates/top-recommended-budgeting-tools',
            //     image: require('images/posts/asset--corona-credit-card-relief-hero.jpg')
            // },
            {
                title: 'COVID-19 is causing you more debt? Then try these 5 tips to manage your finances during a pandemic',
                link: 'https://www.creditsesame.com/blog/debt/5-tips-manage-finances-during-pandemic/',
                image: require('images/posts/asset--corona-debtmanage-hero.jpg')
            },
            {
                title: 'Our Credit Card Partners and Banks That are Offering Temporary Relief',
                link: 'https://www.creditsesame.com/blog/updates/credit-card-partners-and-banks-offering-temporary-relief/',
                image: require('images/posts/asset--corona-credit-card-relief-hero.jpg')
            },
            {
                title: 'Utilities, Cell Phone and Cable Companies That are Offering Help',
                link: 'https://www.creditsesame.com/blog/updates/utilities-cell-phone-cable-companies-offering-help/',
                image: require('images/posts/asset--corona-utilities-bills-hero.jpeg')
            },
            {
                title: 'Wondering What to Do About Your Rent or Mortgage?',
                link: 'https://www.creditsesame.com/blog/updates/what-about-rent-mortgage/',
                image: require('images/posts/asset--rentmortageandcorona-hero.jpeg')
            },
            {
                title: 'Do’s and Don’ts of Budgeting During a Crisis',
                link: 'https://www.creditsesame.com/blog/updates/budgeting-during-crisis/',
                image: require('images/posts/asset--corona-budgeting-hero.jpg')
            },
            {
                title: 'Ways You Can Stay Financially Stable, Maximize Your Available Cash and Credit, Prepare for Emergencies, and Manage Your Credit',
                link: 'https://www.creditsesame.com/blog/updates/stay-financially-stable-maximize-your-available-cash-and-credit-prepare-for-emergencies/',
                image: require('images/posts/asset--corona-maximize-available-cash-hero.jpeg')
            },
            {
                title: 'What you Should Do About the New Low Interest Rates',
                link: 'https://www.creditsesame.com/blog/updates/new-low-interest-rates/',
                image: require('images/posts/asset--corona-interest-rates-hero.jpeg')
            },
        ]
    },
    {
        categoryTitle: 'Managing and Protecting Your Credit',
        //categoryLink: 'https://google.ca/',
        categoryViewAllText: 'View all Articles',
        articles: [
            {
                title: 'How can COVID-19 negatively affect my credit? Check these 5 factors to see if you’re at risk',
                link: 'https://www.creditsesame.com/blog/credit-score/can-covid-19-negatively-affect-credit/',
                image: require('images/posts/asset--corona-affect-credit-score-hero.jpg')
            },
            {
                title: 'Don’t Like Your Credit Score? Improve Your Score With These 4 Simple Tips',
                link: 'https://www.creditsesame.com/blog/credit-score/dont-like-credit-score/',
                image: require('images/posts/asset--dontlikecreditscore-hero.jpg')
            },
            {
                title: 'Recent Questions From Our Live Twitter Q&A',
                link: 'https://www.creditsesame.com/blog/updates/recent-questions-twitter/',
                image: require('images/posts/asset--corona-twitter-qanda-hero.jpeg')
            },

        ]
    }
]


export default [
    {
        title: 'Payment History',
        copy: 'The number one factor in your credit score is your payment history, which accounts for about 35% of your score. Late payments quickly drop your credit score. Check out these tips to help make sure you pay on time, every time.',
        youtubeId: 'VJKkzZzniMo'
    },
    {
        title: 'Credit Usage',
        copy: 'Credit Usage is the second most-important factor in your credit score, making up about 30% of your score. Find out why lowering your credit usage is the fastest way to improve your credit score.',
        youtubeId: 'NPWKLkGkVLU'
    },
    {
        title: 'Account Mix',
        copy: 'Consumers with the highest credit scores tend to have a different mix of accounts, from credit cards to auto loans. This makes up about 10% of your credit score and shows lenders that you can handle different types of financing. Check out these tips for improving your account mix. ',
        youtubeId: 'gjV01eizmsU'
    },
    {
        title: 'Credit Inquiries',
        copy: 'There are two types of credit inquiries—hard and soft. These make up about 10% of your credit score. Learn about the difference and how each of these can impact your credit score.',
        youtubeId: '4beLiQjcMFk'
    },
    {
        title: 'Credit Age',
        copy: 'Your credit age makes up about 15% of your credit score, and is not something that you can improve quickly. Check out these tips for helping to ensure a longer credit age on your credit report.',
        youtubeId: 'i7n132NiWoY'
    },
]
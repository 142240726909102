import { scrollTo } from "utility";

export function toggleHeader(scrollTop) {
  const header = document.getElementById("header");
  const hero = document.getElementById("hero");
  const thresh = hero.clientHeight;

  if (scrollTop > thresh - 150) {
    header.classList.add("active");
  } else {
    header.classList.remove("active");
  }
}

const openClass = "download-open";
export const togglePopupClass = "toggle-popup";

export function toggleDownload() {
  const header = document.getElementById("header");

  document.addEventListener("click", function(e) {
    if (e.target && e.target.classList.contains(togglePopupClass)) {
      header.classList.add(openClass);
    }
  });
}

function bindClickToSection() {
  const navItems = document.querySelectorAll("[data-scroll-to]");

  Array.prototype.forEach.call(navItems, function(el, index, array) {
    el.addEventListener("click", () => {
      const idOfSection = el.getAttribute("data-scroll-to");
      scrollTo(`#${idOfSection}`);
    });
  });
}

export function closeDownloadAction() {
  const header = document.getElementById("header");
  header.classList.remove(openClass);
}

export function closeDownload() {
  const closeButton = document.getElementById("closeButton");

  closeButton.addEventListener("click", function() {
    closeDownloadAction()
  });
}

export function init() {
  toggleDownload();
  closeDownload();
  bindClickToSection();

  setTimeout(() => {}, 200);
}
